import { NewPropertyExecTypeModal } from './../../property-execution-type/new-region-modal/new-property-exec-type-modal.component';
import { NewCountyModalComponent } from './../../county-modals/new-region-modal/new-county-modal.component';
import { Region, County, PropertyExecutionType } from './../../administration.models';
import { PropertyService } from '../property.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NewRegionModalComponent } from './../../region-modals/new-region-modal/new-region-modal.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit, OnDestroy {
  modalRef?: BsModalRef;
  propertyForm: UntypedFormGroup;
  regionsIsLoading = false;
  countiesIsLoading = false;
  formIsLoading = false;
  PropertyExecutionTypeIsLoading = false;
  modalSubscription: Subscription = Subscription.EMPTY;

  regions: Region[];
  counties: County[];
  propertyExecutionTypes: PropertyExecutionType[];
  constructor(
    private modalService: BsModalService,
    private propertyService: PropertyService,
    private router: Router
    ) { 
      this.createForm();
    }

  ngOnInit() {
   this.getRegions();
   this.getCounties();
   this.getPropertyExecutionType();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.modalSubscription.unsubscribe();
  }

  openNewRegionModal(): void {
    this.modalRef = this.modalService.show(NewRegionModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getRegions();
    });
  }

  openNewCountyModal(): void {
    this.modalRef = this.modalService.show(NewCountyModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getCounties();
    });
  }

  openNewPropertyExecTypeModal(): void {
    this.modalRef = this.modalService.show(NewPropertyExecTypeModal,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getPropertyExecutionType();
    });
  }

  submitForm() {
    if(this.propertyForm.valid) {
      this.formIsLoading = true;

      this.propertyService.saveProperty(this.propertyForm.value)
        .pipe(finalize(() => {
          this.formIsLoading = false;
        }))
        .subscribe(data => {
          
          this.router.navigate(['admin/property/', data.id ]);
        }
        );
      
    } else {
      this.propertyForm.markAllAsTouched();
    }
  }

  private createForm(): void {
    this.propertyForm = new UntypedFormGroup({
      regionId: new UntypedFormControl('', Validators.required),
      countyId: new UntypedFormControl('', Validators.required),
      propertyName: new UntypedFormControl('', Validators.required),
      kadestsNumber: new UntypedFormControl('', Validators.required),
      propertyConditionMark: new UntypedFormControl('', Validators.required),
      propertyExecutionTypeId: new UntypedFormControl('', Validators.required),
      propertyArea: new UntypedFormControl('', Validators.required),
      forestArea: new UntypedFormControl('', Validators.required),
      distanceToAGK: new UntypedFormControl('', Validators.required),
      startingPrice: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      minimalBiddingStep: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      maximalBiddingStep: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      duePayDateTime: new UntypedFormControl('', Validators.required),
      allowedToWorkTillDateTime: new UntypedFormControl('', Validators.required),
      auctionEnds: new UntypedFormControl('', Validators.required),
      // propertyTreeQuantities: new FormGroup({
      //   treeTypeId: new FormControl('', Validators.required),
      //   quantity: new FormControl('', [Validators.required, Validators.min(0)]),
      // })
    })
  }

  private getRegions() {
    this.regionsIsLoading = true;
    this.propertyService.getAllRegions()
      .pipe(finalize(() => this.regionsIsLoading = false))
      .subscribe(data => {
        this.regions = data;
      })
  }

  private getCounties() {
    this.countiesIsLoading = true;
    this.propertyService.getAllCounties()
      .pipe(finalize(() => this.countiesIsLoading = false))
      .subscribe(data => {
        this.counties = data;
      });
  }

  private getPropertyExecutionType() {
    this.PropertyExecutionTypeIsLoading = true;
    this.propertyService.getAllExecutionTypes()
      .pipe(finalize(() => this.PropertyExecutionTypeIsLoading = false))
      .subscribe(data => {
        this.propertyExecutionTypes = data;
      });
  }
}
