import { PropertyTreeQuantity } from './../../../models/all.models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tree-quantities',
  templateUrl: './tree-quantities.component.html',
  styleUrls: ['./tree-quantities.component.css']
})
export class TreeQuantitiesComponent implements OnInit {

  @Input() treeQuantities: PropertyTreeQuantity[];
  @Input() treeQuantityTotal: number;
  constructor() { }

  ngOnInit() {

  }

}
