export class Property {
    id: number;
    region: {
        id: number;
        name: string;
    }
    
    county: {
        id: number;
        name: string;
        thumbnail: string;

    }
    propertyExecutionType: {
        propertyExecutionTypeId: number;
        name: string;
    }
    propertyArea: string;
    distanceToAGK: string;    
    isEnabled: boolean;
    startingPrice: number;
    startDate: Date;
    propertyName: string;
    minimalBiddingStep: number;
    maximalBiddingStep: number;
    suePayDateTime: Date;
    allowedToWorkTillDateTime: Date;
    auctionEnds: Date;
    
    public constructor(init?: Partial<Property>) {
        Object.assign(this, init);
    }
}


export class PropertyWithBid extends Property {
    auctionBids : AuctionBid[];
    isSubscribed: boolean;
}

export class FilteredProperties extends Property {
    properties : PropertyWithBid[];
    hasMore: boolean;
}

export class PropertyDetails {
    id: number;
    region: {
        id: number;
        name: string;
    }
   
    county: {
        id: number;
        name: string;
    }
    propertyExecutionType: {
        id: number;
        name: string;
    }
    propertyArea: string;
    forestArea: string;
    propertyName: string;
    distanceToAGK: string;    
    startingPrice: number;
    minimalBiddingStep: number;
    virtualTourUrl: string;
    youtubeVideoUrl: string
    maximalBiddingStep: number;
    duePayDateTime: Date;
    
    kadestsNumber: string;
    allowedToWorkTillDateTime: Date;
    auctionEnds: Date;
    propertyTreeQuantities: PropertyTreeQuantity[];
    files: FileDto[];

    public constructor(init?: Partial<PropertyDetails>) {
        Object.assign(this, init);
    }
}

export class PropertyFilter {
    propertyExecutionTypeId: number = null;
    isActive: boolean = null;
    isEnded: boolean = null;
    isScheduled: boolean = null;
    regionId: number = null;
    take: number;
    skip: number;

    public constructor(init?: Partial<PropertyFilter>) {
        Object.assign(this, init);
    }
}

export class AuctionBid {
    id: number;
    userId: string;
    price: number;
    bidDate: Date;
    public constructor(init?: Partial<AuctionBid>) {
        Object.assign(this, init);
    }
}

export class AuctionBidWithHistory {
    id: number;
    userId: string;
    price: number;
    bidDate: Date;
    auctionEndDate: Date
    bidHistory: BidHistory[];
}

export class BidHistory {
    bidDate: Date;
    price: number;
}

export class Contact {
    name: string;
    phone: string;
    message: string;
    public constructor(init?: Partial<AuctionBid>) {
        Object.assign(this, init);
    }
}


export class CreateBidDto {
    propertyId: number;
    price: number;
    public constructor(init?: Partial<AuctionBid>) {
        Object.assign(this, init);
    }
}

export class PropertyTreeQuantity {
    id: number;
    propertyId: number;
    treeType: TreeType;
    quantity: number;
    public constructor(init?: Partial<PropertyTreeQuantity>) {
        Object.assign(this, init);
    }
}

export class TreeType {
    id: number;
    name: string;
    public constructor(init?: Partial<TreeType>) {
        Object.assign(this, init);
    }
}

export class FileDto {
    id: number;
    fileName: string;
    fileSize: number;
    uploadDate: Date;
    uploadedBy: string;

    public constructor(init?: Partial<File>) {
        Object.assign(this, init);
    }
}

export class CompanyLookupDto{
    registrationCountry: string;
    name: string;
    legalAddress: string;
    registrationNumber: string;
    vatNumber: string;
    public constructor(init?: Partial<CompanyLookupDto>) {
        Object.assign(this, init);
    }
}