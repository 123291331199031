<div class="container page-container">
  <div class="row">
    <div class="col-12">
      <h1>Izsoles nolikums</h1>
      <p><strong>Elektroniskās Izsoļu Vietnes www.mezsole.lv Nolikums - Cirsmu un Meža Īpašumu Pārdošanai</strong></p>
      <p>Šis nolikums ("Nolikums") nosaka noteikumus un nosacījumus, kas jāievēro, piedaloties cirsmu un meža īpašumu iegādē www.mezsole.lv elektroniskajā izsoļu vietnē. Lūdzu, rūpīgi izlasiet šos noteikumus, pirms piedalāties izsolē. Izmantojot šo vietni un piedaloties izsolē, jūs apliecinat, ka piekrītat un saprotat visus zemāk minētos noteikumus un nosacījumus.</p>
      <ol>
        <li>
          <strong>Piedalīšanās un Izsoles Process:</strong>
          <ol>
            <li>
              Izsoles dalībniekiem jāveic reģistrācija, jāapstiprina e-pasta adrese, jāparaksta izsoles dalībnieka līgums un jāsamaksā drošības depozīta maksa, lai piedalītos izsolē.
            </li>
            <li>
              Izsoles process paliek nemainīgs katrā izsolē, ietverot sākuma un beigu datumus un laikus, kā arī citu informāciju, kas nepieciešama, lai iepazītos ar Izsoles objektu.
            </li>
            <li>Izsoles uzvarētājam jāveic apmaksa, kā norādīts, cirsmu pirkuma vai īpašuma iegādes līgumā.</li>
          </ol>
        </li>
        <li>
          <strong>Atsakoties no Īpašuma vai Cirsmas Pēc Uzvaras:</strong>
          <ol>
            <li>
              Izsoles uzvarētājam, kurš pēc uzvaras atsakās no īpašuma vai cirsmas iegādes, zaudē drošības depozīta maksu, kā arī maksā līgumsodu 2% apmērā no Izsoles objekta gala vērtības.
            </li>
            <li>
              Tikai pēc uzvarētāja atteikuma izsole tiks atkārtoti rīkota 7 kalendāro dienu laikā no sākotnējās izsoles datumu beigām.
            </li>
          </ol>
        </li>
        <li>
          <strong>Nepabeigta Izsole:</strong>
          <ol>
            <li>Ja izsoles laikā nav izdarīta neviena likme, izsole tiks atkārtoti rīkota 7 kalendāro dienu laikā no sākotnējās izsoles datumu beigām.</li>
          </ol>
        </li>
        <li>
          <strong>Izsoles Rīkotājs:</strong>
          <ol>
            <li>
              Izsoles rīkotājs ir "Mežsole SIA", izsoļu vietne www.mezsole.lv, reģistrēts saskaņā ar Latvijas likumdošanu. 
            </li>
            <li>
              Izsoles rīkotājs patur tiesības mainīt izsoles noteikumus, ja nepieciešams, un to darīt, paziņojot dalībniekiem laicīgi.
            </li>
          </ol>
        </li>
        <li>
          <strong>Pārējie Noteikumi:</strong>
          <ol>
            <li>
              Citas svarīgas informācijas un noteikumus par izsolītajiem objektiem, tostarp apmaksas, objektu stāvokli, var atrast izsoles noteikumos katrā konkrētajā izsolē.
            </li>
            <li>
              Jūsu izsoles vietnes lietošana ir pakļauta privātuma politikai un lietošanas noteikumiem, kas atrodami mūsu vietnē.
            </li>
          </ol>
        </li>
        <li>
          <strong>Piekļuves Dati Pēc Reģistrācijas un Drošības Naudas Apmaksas:</strong>
          <ol>
            <li>
              Pēc izsoles dalībnieka veiksmīgas reģistrācijas un drošības naudas apmaksas 500.00 EUR vērtībā, izsoļu rīkotājs nodrošina elektroniskās izsoļu vietnes piekļuves datus dalībniekam. 
            </li>
            <li>
              Piekļuves dati ietver lietotājvārdu un drošu paroli, kas ļauj dalībniekam piekļūt un piedalīties izsolē.
            </li>
          </ol>
        </li>
      </ol>
    </div>

  </div>
</div>