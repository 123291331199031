<div class="w-100">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Suga</th>
                <th scope="col">Kopējā krāja m&#179;</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="treeQuantities.length > 0">
                <tr *ngFor="let treeQuantity of treeQuantities; let i = index">
                    <th scope="row">{{i}}</th>
                    <td>{{treeQuantity.treeType.name}}</td>
                    <td>{{treeQuantity.quantity | number:'3.2-5'}}</td>
                </tr>
            </ng-container>
            
        </tbody>
    </table>
    <strong>Kopā: {{treeQuantityTotal | number:'3.2-5'}} m&#179;</strong>
</div>