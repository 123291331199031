<ng-container *ngIf="!isLoading; else loading">
  <div class="container container-wider page-container">
    <div class="">
      <div class="row">
        <div class="col-12 pb-4">
          <div class="d-lg-flex justify-content-lg-between" style="align-items: center;">
            <div></div>
            <!-- <h3 class="date-label">{{auctionProperty.auctionEnds | date: 'dd/MM/yyyy HH:mm'}}</h3> -->
            <ng-container *ngIf="!autctionEnded">
              <div class="date-label" [class]="isTimeLessThanTwoMins ? 'text-danger' : ''">
                Līdz izsoles beigām:
                <strong>
                <span *ngIf="daysToDday > 0" id="days" class="ml-0">{{daysToDday}} d
                  {{hoursToDday }}h:{{minutesToDday}}m:{{secondsToDday}}s</span>
                  <span *ngIf="daysToDday == 0" id="days" class="ml-0">
                    {{hoursToDday }}h:{{minutesToDday}}m:{{secondsToDday}}s</span> </strong>
              </div>
              <!-- <p style="color:black"><strong>Solījums: </strong> {{actualPrice | number: '0.2'}}</p> -->
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-lg-5 order-2 order-lg-1 mt-3 mt-lg-0">
          <!-- <h3 class="auc-title">{{auctionProperty.propertyExecutionType.name}}, {{auctionProperty.region.name}} #{{auctionProperty.id}}
          </h3> -->
          <h3 class="auc-title">{{auctionProperty.propertyExecutionType.name}} #{{auctionProperty.id}}
          </h3>
          <div class="info-container">
            <div class="object-title">
              <div class="d-flex title">
                <h4 class="m-auto">Īpašuma izsole <span class="dot"></span> <span *ngIf="!autctionEnded">Aktīva</span> <span class="text-danger" *ngIf="autctionEnded">Noslēgusies</span></h4>
              </div>
              <p class="pt-2 text-center">
                {{auctionProperty.county.name}}, {{auctionProperty.propertyName}}.
              </p>
              <p class="text-center end-date" *ngIf="!autctionEnded">
                Noslēdzas: {{auctionProperty.auctionEnds | date: 'dd.MM.yyyy HH:mm'}}
              </p>
            </div>
            <hr class="mt-4">
            <table class="w-100">
              <tr>
                <td><strong>Kadastra numurs</strong></td>
                <td><a class="info-text-color" target="_blank"
                    href="https://www.kadastrs.lv/properties/search?cad_num={{kadester}}">{{kadester}}</a>
                </td>
              </tr>
              <tr *ngFor="let kad of kadesterSections">
                <td  ><strong>Zemes vienības kadastra <br>apzīmējums</strong></td>
                <td><a class="info-text-color" target="_blank" href="https://www.kadastrs.lv/properties/search?cad_num={{kad}}">{{kad}}</a>
                </td>
              <tr>
                <td><strong>Cirsmas izpildes veids</strong></td>
                <td class="info-text-color" >{{auctionProperty.propertyExecutionType.name}}</td>
              </tr>
              <tr *ngIf="auctionProperty.forestArea">
                <td><strong>Meža zemes platība</strong></td>
                <td class="info-text-color" >{{auctionProperty.forestArea}}</td>
              </tr>
              <tr *ngIf="auctionProperty.propertyArea">
                <td><strong>Meža īpašuma kopplatība</strong></td>
                <td class="info-text-color" >{{auctionProperty.propertyArea}}</td>
              </tr>
            </table>

            <div class="row mt-4">
              <div class="col-12 text-center">
                <button class="btn btn-white mb-3 m-auto coll-btn" [ngClass]="isCollapsed ? 'active' : ''" (click)="toggleCollapse()">
                  <h5>Saistošie dokumenti</h5>
                </button>
              
                <div *ngIf="isCollapsed" [@slideToggle] class="doc-container">
                  <app-property-documents class="w-100" [files]="auctionProperty.files"
                  [isUserLoggedId]="userIsLoggedIn"></app-property-documents>
                </div>
              </div>
            </div>

            <ng-container *ngIf="safeYoutubeVideoUrl && auctionProperty.youtubeVideoUrl !== '-'">
              <hr class="mt-4">
              <div class="row mt-4">
                <div class="col-12">
                  <h5 class="text-center">Īpašuma prezentācija</h5>
                  <iframe width="100%" [src]="safeYoutubeVideoUrl"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                </div>
              </div>
            </ng-container>

            <hr>
            <div class="row mt-4">
              <div class="col-12">
                <h5 class="text-center">Par darījumu atbildīgā persona</h5>

                <div class="responsible-person">
                  <div class="">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541" class="avatar">
                  </div>
                  <div class="mt-3">
                    <p class="mb-0"><strong>Reinis Dāvidsons</strong></p>
                    <p class="mb-0">reinis.davidsons@mezsole.lv</p>
                    <p class="mb-0">28 270 059</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7 px-lg-3 order-1 order-lg-2">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="info-tab d-flex justify-content-between">
                <div class="icon">
                  <span class="icon-icon-tractor"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span><span class="path4"></span><span class="path5"></span><span
                      class="path6"></span><span class="path7"></span><span class="path8"></span><span
                      class="path9"></span><span class="path10"></span><span class="path11"></span><span
                      class="path12"></span><span class="path13"></span><span class="path14"></span><span
                      class="path15"></span><span class="path16"></span><span class="path17"></span><span
                      class="path18"></span><span class="path19"></span><span class="path20"></span><span
                      class="path21"></span><span class="path22"></span><span class="path23"></span><span
                      class="path24"></span><span class="path25"></span><span class="path26"></span><span
                      class="path27"></span><span class="path28"></span><span class="path29"></span><span
                      class="path30"></span><span class="path31"></span><span class="path32"></span><span
                      class="path33"></span><span class="path34"></span><span class="path35"></span><span
                      class="path36"></span><span class="path37"></span><span class="path38"></span><span
                      class="path39"></span><span class="path40"></span></span>
                </div>
                <div class="info-tab-explanatory">
                  <h5><strong>{{auctionProperty.propertyConditionMark}}/10</strong></h5>
                  <h6 class="mb-0">Mežistrādes apstākļi</h6>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 pl-lg-0 pt-2 pt-lg-0">
              <div class="info-tab d-flex justify-content-between">
                <div class="icon">
                  <span class="icon-icon-map"></span>
                </div>
                <div class="info-tab-explanatory">
                  <h5><strong>{{auctionProperty.distanceToAGK}}</strong></h5>
                  <h6 class="mb-0">Pievešanas attālums</h6>
                </div>
              </div>
              
            </div>
            <div class="col-12 mt-4 mb-2">
              <div class="auction-tab">
                <div class="row">
                  <ng-container *ngIf="!initialBidHistoryLoading; else smallLoading">
                    <div class="col-12 col-lg-7 auction-tab-contents">
                      <ng-container *ngIf="autctionEnded">
                        <div class="marker">
                        </div>
                        <div class="col-12 box ml-0 pl-0">
                          <div class="auction-block d-block">
                            <ng-container *ngIf="bidHistory?.length">
                              <p class="w-100 mb-1">
                                Izsoles sākuma cena <strong>{{auctionProperty.startingPrice | number: '0.2'}}</strong>
                              </p>
                              <p class="w-100 mb-1">
                                Izsoles noslēguma cena <strong>{{actualPrice | number: '0.2'}}</strong>
                              </p>
                              <p class="w-100 mb-1">
                                Solījumu skaits: <strong>{{bidHistory?.length}}</strong>
                              </p>
                            </ng-container>
                            <ng-container *ngIf="!initialBidHistoryLoading && !bidHistory?.length">
                              <p class="w-100 mt-4 text-center">Izsole noslēgusies bez neviena solījuma.</p>
                            </ng-container>
                          </div>
                          <ng-container *ngIf="youare">
                            <div class="pl-1 alert alert-success d-flex mt-3 mb-0">
                              <span class="icon-icon-auction"><span class="path1"></span><span
                                  class="path2"></span><span class="path3"></span><span class="path4"></span><span
                                  class="path5"></span><span class="path6"></span><span class="path7"></span><span
                                  class="path8"></span><span class="path9"></span></span>
                              <p class="mb-0">
                                Apsveicam, esat uzvarējis izsoli!
                              </p>
                            </div>
                          </ng-container>
                        </div>

                      </ng-container>
                      <div class="row" *ngIf="!autctionEnded">
                        <div class="col-12 box">
                          <div class="auction-block">
                            <ng-container *ngFor="let err of bidValidationError">
                              <span class="text-danger">{{err}}</span>
                            </ng-container>
                            <ng-container *ngIf="userIsLoggedIn; else pleaseAuthorize">
                              <ng-container *ngIf="userCanBid">
                                <ng-container *ngIf="!youare; else highestBidded">
                                  <ng-container *ngIf="!initialBidHistoryLoading; else loadingSmall">
                                    <form [formGroup]="auctionForm" (ngSubmit)="submitForm()">
                                      <div class="row">
                                        <div class="col-8 d-flex pr-0 justify-content-between">
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" (change)="setBidValue(100)" type="radio"
                                              name="inlineRadioOptions" id="inlineRadio1" value="option1" selected>
                                            <label class="form-check-label" for="inlineRadio1">+100</label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" (change)="setBidValue(500)" type="radio"
                                              name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                            <label class="form-check-label" for="inlineRadio2">+500</label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" (change)="setBidValue(1000)" type="radio"
                                              name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                            <label class="form-check-label" for="inlineRadio3">+1000</label>
                                          </div>
                                        </div>
                                        <div class="col-8 pr-0">
                                          <input class="form-control bid-form" name="price" formControlName="price"
                                            [class.is-invalid]="!auctionForm.controls['price'].valid && auctionForm.controls['price'].touched">
                                          <p class="gray-text">* Komisija ir iekļauta</p>
                                        </div>

                                        <div class="col-4 pl-0">
                                          <button class="btn btn-primary bid-button full-width"
                                            type="submit">Solīt</button>
                                        </div>
                                      </div>
                                    </form>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="!userCanBid">
                                <div class="pl-1 alert alert-danger d-flex justify-content-between m-auto">
                                  <span class="icon-icon-alert mr-2"></span>
                                  <p class="mb-0">Jūsu profils nav apstiprināts, un jūs nevarat piedalīties izzsolē.
                                    <a [routerLink]="['/cabinet']">Uz Kabinetu</a>
                                  </p> .
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-5 p-0">
                      <div class="history-table-container">
                        <table>
                          <thead>
                            <tr>
                              <th class="auction-th"></th>
                              <th class="text-center">Laiks</th>
                              <th>Solījums</th>
                            </tr>
                          <tbody>
                            <ng-container *ngIf="!initialBidHistoryLoading; else bidHistoryLoading">
                              <ng-container *ngIf="bidHistory?.length">
                                <tr *ngFor="let historyItem of bidHistory">
                                  <td class="auction-td text-center">
                                    <img class="auction-icon" src="./../../../assets/images/icon-image/icon-hammer.png">
                                  </td>
                                  <td>
                                    <p class="text-center bid-time">{{historyItem.bidDate.split('T')[1].split('.')[0]}}
                                    </p>
                                    <p class="text-center bid-date">{{historyItem.bidDate.split('T')[0]}}</p>
                                  </td>
                                  <td class="bid-amount">€ {{historyItem.price}}</td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="!bidHistory?.length">
                                <tr>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>

            </div>
            <ng-container *ngIf="safevirtualTourUrl">
              <div class="col-12 pt-3">
                <h4>Īpašuma virtuālā tūre</h4>
                <div class="embed-responsive embed-responsive-16by9 mt-3">
                  <iframe id="kuula" class="embed-responsive-item" allow="xr-spatial-tracking; accelerometer"
                    allowfullscreen="" frameborder="0" scrolling="no" [src]="safevirtualTourUrl"></iframe>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="footer-container">
            <img class="logo" src="./../../../assets/images/logo/Mezsole_logo_yellow.svg">
            <div class="title-container">
              <h2 class="title">Vēlies pieteikt savu meža īpašumu izsolei?</h2>
              <button class="btn btn-primary m-auto" type="button" (click)="navigate()">SAZINĀTIES</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #highestBidded>
  <div class="pl-1 alert alert-success d-flex m-auto">
    <span class="icon-icon-auction"><span class="path1"></span><span class="path2"></span><span
        class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span
        class="path7"></span><span class="path8"></span><span class="path9"></span></span>
    <p class="mb-0">
      Šobrīd Jūs esat augstākais solītājs!
    </p>
  </div>
</ng-template>


<ng-template #pleaseAuthorize>
  <div class="pl-1 alert alert-danger d-flex m-auto">
    <span class="icon-icon-alert mr-2"></span>
    <p class="mb-0">
      Lūdzu autorizējaties lai piedalītos izsolē!</p>
  </div>
</ng-template>

<ng-template #smallLoading>
  <div class="loading-screen">
    <app-small-loading [center]="true"></app-small-loading>
  </div>
</ng-template>

<ng-template #loadingSmall>
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>