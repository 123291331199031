export class Region {
    id: number;
    name: string;
    thumbnail: string;

    public constructor(init?: Partial<Region>) {
        Object.assign(this, init);
    }
}

export class PaginatedResponse<T> {
    data: T[];
    page: number;
    pageSize: number;
    totalItems: number;
  }

export class NewsArticle {
    id: number;
    content: string;
    thumbnail: string;
    shortDescription: string;
    creationDate: Date;
    publicationDate: Date;
    applicationUserId: string;

    public constructor(init?: Partial<Region>) {
        Object.assign(this, init);
    }
}


export class County {
    id: number;
    name: string;
    thumbnail:string;
    
    public constructor(init?: Partial<County>) {
        Object.assign(this, init);
    }
}

export class TreeType {
    id: number;
    name: string;

    public constructor(init?: Partial<TreeType>) {
        Object.assign(this, init);
    }
}

export class PropertyExecutionType {
    id: number;
    name: string;

    public constructor(init?: Partial<PropertyExecutionType>) {
        Object.assign(this, init);
    }
}

export class Property {
    id: number;
    regionIn: number;
    countyId: number;
    propertyName: string;
    kadestsNumber: string;
    propertyExecutionTypeId: number;
    propertyArea: string;
    distanceToAGK: string;    
    propertyForm: Date;
    startingPrice: number;
    minimalBiddingStep: number;
    maximalBiddingStep: number;
    suePayDateTime: Date;
    allowedToWorkTillDateTime: Date;
    auctionEnds: Date;
    
    public constructor(init?: Partial<Property>) {
        Object.assign(this, init);
    }
}

export class UnsignedContract {
    id: number;
    fileName: string;
    companyName: string;
    firstName: string;
    surname: string;
    email: string;
    applicationUserId: string;
}

export class UserRoleDTO{
    id: string;
    companyName: string;
    companyRegistrationNumber: string;
    email: string;
    firstName: string;
    surname: string;
    public constructor(init?: Partial<UserRoleDTO>) {
        Object.assign(this, init);
    }
}

export class UserWithRoles{
    user: UserRoleDTO;
    roles: UserRoleAssignment[];
    public constructor(init?: Partial<UserRoleDTO>) {
        Object.assign(this, init);
    }
}

export class UserRoleAssignment{
    id: string;
    name: string;
    selected: boolean;
}
