<form [formGroup]="propertyForm" (ngSubmit)="submitForm()">
  <h2>Pievienot jaunu īpašumu</h2>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="regionId">Reģions</label>
        <ng-container *ngIf="!regionsIsLoading else loading">
          <select class="form-control" id="regionId" name="regionId" formControlName="regionId"
            [class.is-invalid]="!propertyForm.controls['regionId'].valid && propertyForm.controls['regionId'].touched">
            <option *ngFor="let reg of regions" [ngValue]="reg.id">{{reg.name}}</option>
          </select>
          <span class="text-danger"
            *ngIf="!propertyForm.controls['regionId'].valid && propertyForm.controls['regionId'].touched">
            Reģiona jābūt izvēlētam
          </span>
        </ng-container>
      </div>
    </div>
    <div class="col-1 pl-0">
      <button type="button" class="btn-border-less" (click)="openNewRegionModal()">
        <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="countyId">Novads</label>
        <ng-container *ngIf="!countiesIsLoading else loading">
          <select class="form-control" id="countyId" name="countyId" formControlName="countyId"
            [class.is-invalid]="!propertyForm.controls['countyId'].valid && propertyForm.controls['countyId'].touched">
            <option *ngFor="let c of counties" [ngValue]="c.id">{{c.name}}</option>
          </select>
          <span class="text-danger"
            *ngIf="!propertyForm.controls['countyId'].valid && propertyForm.controls['countyId'].touched">
            Novadam jābūt izvēlētam
          </span>
        </ng-container>
      </div>
    </div>
    <div class="col-1 pl-0">
      <button type="button" class="btn-border-less" (click)="openNewCountyModal()">
        <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-11">
      <div class="form-group">
        <label for="propertyName">Īpašuma nosaukums/adrese</label>
        <input type="text" class="form-control" name="propertyName" formControlName="propertyName"
          [class.is-invalid]="!propertyForm.controls['propertyName'].valid && propertyForm.controls['propertyName'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['propertyName'].valid && propertyForm.controls['propertyName'].touched">
          Laukam jābūt norādītam jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-11">
      <div class="form-group">
        <label for="kadestsNumber">Kadestra numurs</label>
        <input type="text" class="form-control" name="kadestsNumber" formControlName="kadestsNumber"
          [class.is-invalid]="!propertyForm.controls['kadestsNumber'].valid && propertyForm.controls['kadestsNumber'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['kadestsNumber'].valid && propertyForm.controls['kadestsNumber'].touched">
          Laukam jābūt norādītam jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="regionId">Cirtes izpildes veidi</label>
        <ng-container *ngIf="!countiesIsLoading else loading">
          <select class="form-control" id="propertyExecutionTypeId" name="propertyExecutionTypeId"
            formControlName="propertyExecutionTypeId"
            [class.is-invalid]="!propertyForm.controls['propertyExecutionTypeId'].valid && propertyForm.controls['propertyExecutionTypeId'].touched">
            <option *ngFor="let c of propertyExecutionTypes" [ngValue]="c.id">{{c.name}}</option>
          </select>
          <span class="text-danger"
            *ngIf="!propertyForm.controls['propertyExecutionTypeId'].valid && propertyForm.controls['propertyExecutionTypeId'].touched">
            Cirtes izpildes veidam jābūt izvēlētam
          </span>
        </ng-container>
      </div>
    </div>
    <div class="col-1 pl-0">
      <button type="button" class="btn-border-less" (click)="openNewPropertyExecTypeModal()">
        <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="propertyArea">Cirsmas kopplatība</label>
        <input type="text" class="form-control" name="propertyArea" formControlName="propertyArea"
          [class.is-invalid]="!propertyForm.controls['propertyArea'].valid && propertyForm.controls['propertyArea'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['propertyArea'].valid && propertyForm.controls['propertyArea'].touched">
          Cirsmas kopplatībai jābūt aizpildītai!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="propertyConditionMark">Cirsmas stāvokļa apzīmējums</label>
        <input type="text" class="form-control" name="propertyConditionMark" formControlName="propertyConditionMark"
          [class.is-invalid]="!propertyForm.controls['propertyConditionMark'].valid && propertyForm.controls['propertyConditionMark'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['propertyConditionMark'].valid && propertyForm.controls['propertyConditionMark'].touched">
          cirsmas stāvok jābūt aizpildītai!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="forestArea">Meža kopplatība</label>
        <input type="text" class="form-control" name="forestArea" formControlName="forestArea"
          [class.is-invalid]="!propertyForm.controls['forestArea'].valid && propertyForm.controls['forestArea'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['forestArea'].valid && propertyForm.controls['forestArea'].touched">
          Cirsmas kopplatībai jābūt aizpildītai!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="distanceToAGK">Attālums lidz AGK</label>
        <input type="text" class="form-control" name="distanceToAGK" formControlName="distanceToAGK"
          [class.is-invalid]="!propertyForm.controls['distanceToAGK'].valid && propertyForm.controls['distanceToAGK'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['distanceToAGK'].valid && propertyForm.controls['distanceToAGK'].touched">
          Attālumam jābūt norādītam jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="startingPrice">Izsoles sākumcena:</label>
        <input type="number" class="form-control" name="startingPrice" formControlName="startingPrice"
          [class.is-invalid]="!propertyForm.controls['startingPrice'].valid && propertyForm.controls['startingPrice'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['startingPrice'].valid && propertyForm.controls['startingPrice'].touched">
          Izsoles sākumcenai jābūt norādītai!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="minimalBiddingStep">Izsoles minimālais solis</label>
        <input type="number" class="form-control" name="minimalBiddingStep" formControlName="minimalBiddingStep"
          [class.is-invalid]="!propertyForm.controls['minimalBiddingStep'].valid && propertyForm.controls['minimalBiddingStep'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['minimalBiddingStep'].valid && propertyForm.controls['minimalBiddingStep'].touched">
          Minimālajam solim jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="maximalBiddingStep">Izsoles maksimālais solis:</label>
        <input type="number" class="form-control" name="maximalBiddingStep" formControlName="maximalBiddingStep"
          [class.is-invalid]="!propertyForm.controls['maximalBiddingStep'].valid && propertyForm.controls['maximalBiddingStep'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['maximalBiddingStep'].valid && propertyForm.controls['maximalBiddingStep'].touched">
          Minimālajam solim jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="duePayDateTime">Apmaksas termiņš:</label>
        <input type="date" class="form-control" name="duePayDateTime" formControlName="duePayDateTime"
          [class.is-invalid]="!propertyForm.controls['duePayDateTime'].valid && propertyForm.controls['duePayDateTime'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['duePayDateTime'].valid && propertyForm.controls['duePayDateTime'].touched">
          Minimālajam solim jābūt norādītam!
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="auctionEnds">Izsole beidzas:</label>
        <input type="datetime-local" class="form-control" name="auctionEnds" formControlName="auctionEnds"
          [class.is-invalid]="!propertyForm.controls['auctionEnds'].valid && propertyForm.controls['auctionEnds'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['auctionEnds'].valid && propertyForm.controls['auctionEnds'].touched">
          Minimālajam solim jābūt norādītam!
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="allowedToWorkTillDateTime">Atļauts strādāt līdz:</label>
        <input type="date" class="form-control" name="allowedToWorkTillDateTime" formControlName="allowedToWorkTillDateTime"
          [class.is-invalid]="!propertyForm.controls['allowedToWorkTillDateTime'].valid && propertyForm.controls['allowedToWorkTillDateTime'].touched">
        <span class="text-danger"
          *ngIf="!propertyForm.controls['allowedToWorkTillDateTime'].valid && propertyForm.controls['allowedToWorkTillDateTime'].touched">
          Lielumam jābūt aizpildītam!
        </span>
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-primary">Pievienot</button>
</form>
<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>